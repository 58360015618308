import React, { Component } from "react";
import { motion } from 'framer-motion';
import parse from "html-react-parser";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';

import UploadService from "../services/file-upload-enlarger.service";
import { Context } from "../Context";
import { APP_NAME, SCALE_OPTIONS, TOTAL_FILE_SIZE } from "../constants";
import { getSpecialMessageHTML } from "../utils";
import { PopupShare } from "./Share/share.component";

export default class UploadImages4Enlarger extends Component {
  static contextType = Context;
  timer = null;
  duration = 50 * 1000; 
  
  constructor(props) {
    super(props);
    this.selectFiles = this.selectFiles.bind(this);

    this.state = {
      selectFiles: undefined,
      previewImages: [],
      progress: 0,
      message: "",

      scaleFactor: 2,
      watermark: window.location.pathname.endsWith("nwm") ? false : true,
  
      resultsFolder: undefined,
      imageInfos: [],

      startTime: undefined,
    };
  }

  componentDidMount() {
    if (this.state.resultsFolder !== undefined) {
      UploadService.getFiles(this.state.resultsFolder).then((response) => {
        this.setState({
          imageInfos: response.data,
        });
      }); 
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  selectFiles = event => {
    const acceptedFiles = [];
    const images = [];
    let totalSize = 0;
    for (let i = 0; i < event.target.files.length; i++) {
      let currentFile = event.target.files[i];
      if (currentFile.size < 100)  //probably an invalid image file, skip it
        continue;
      totalSize += currentFile.size;
      if (totalSize > TOTAL_FILE_SIZE) 
        break;
      acceptedFiles.push(currentFile);
      images.push(URL.createObjectURL(currentFile));
    }

    if (acceptedFiles.length === 0) {
      this.setState({
        selectedFiles: undefined,
        previewImages: undefined,
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_VALID_FILE_FOUND'),
      });
      return;
    }

    this.setState({
      selectedFiles: acceptedFiles,
      previewImages: images,
      progress: 0,
      message: ""
    });
  }

  onChangeOfScaleFactor = event => {
    this.setState({
      progress: 0,
      scaleFactor: event.target.value,
    });
  }

  uploadAll(files, sid, locale) {
    this.timer = null;
    this.duration = 15 * 1000 * files.length;
    this.setState({
      progress: 0,
      startTime: Date.now(),
    });

    UploadService.upload(files, this.state.scaleFactor, this.state.watermark, sid, locale, (event) => {
      //this.setState({
      //  progress: Math.round(((50 / Math.ceil(files.length / 5)) * event.loaded) / event.total),
      //});
      if (this.timer === null)
        this.timer = setInterval(() => {
          const percentage = Math.round(((Date.now() - this.state.startTime) / this.duration) * 100);
          if (percentage > 96) 
            clearInterval(this.timer);
          else 
            this.setState({ 
              progress: percentage
            })
        }, 200)
    }).then((response) => {
        clearInterval(this.timer);
        this.setState({
          message: response.data.message,
          progress: 100,
          resultsFolder: response.data.userFolder4Enlarger,
        });
      })
      .catch((reason) => {
        clearInterval(this.timer);
        if (typeof reason.response != 'undefined' && typeof reason.response.data != 'undefined') {
          let msg = intl.getHTML('ERROR_MESSAGE_ENLARGER_RESULT_TOO_LARGE');
          if (reason.response.data.message)  // && reason.response.data.message.indexOf('[Errno 199]') !== -1)
            msg = intl.getHTML('ERROR_MESSAGE_KNOWN_REASON', { reason: reason.response.data.message });
          this.setState({
            progress: 0,
            message: msg,
            resultsFolder: reason.response.data.userFolder4Enhancer,
          });
        } else {
          this.setState({
            progress: 0,
            message: intl.getHTML('ERROR_MESSAGE_UNKNOWN_REASON', { reason: reason }),
          });
        }
      })
      .finally(() => {
        if (typeof this.state.resultsFolder !== "undefined")
          UploadService.getFiles(this.state.resultsFolder)
          .then((processedFiles) => {
            this.setState({
              imageInfos: processedFiles.data,
            });
          })
      });
  }

  uploadImages(sid, locale) {
    const selectedFiles = this.state.selectedFiles;
    if (!selectedFiles) {
      this.setState({
        progress: 0,
        message: intl.getHTML('ERROR_MESSAGE_NO_FILE_SELECTED'),
      });
      return;
    }
    this.setState(
      {
        progress: 0,
        message: ""
      },
      () => {
        this.uploadAll(selectedFiles, sid, locale);
      }
    );
  }

  render() {
    const { dynamicText, authenticated, sid, locale, user } = this.context;
    const { selectedFiles, previewImages, progress, message, imageInfos } = this.state;
    const ulClassName = this.props.isLargeScreen ? "card-deck" : "list-group list-group-flush";
    const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

    return (
      <motion.div className="content" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Helmet>
          <title>{APP_NAME} { intl.get('Enlarger') }</title>
          <meta name="description" content={ intl.get('Enlarger_info') } />
        </Helmet>
        <div className="title">{ intl.get('Enlarger') }</div>
        {specialMessage && (
          <div className="alert alert-danger mt-2">
            {parse(specialMessage)}
          </div>
        )}
        
        {(!authenticated || user.displayMode === '1') && this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-5 align-self-center introduction">
               { intl.getHTML('Enlarger_description') }
            </div>
            <div className="col-7 text-center">
              <img src="/images/enlarger2.jpg" alt="Enlarger example 2" className="img-fluid" />
            </div>
          </div>
        )}
        {(!authenticated || user.displayMode === '1') && !this.props.isLargeScreen && (
          <div className="row m-1">
            <div className="col-12 text-center">
              <div className="introduction">
                { intl.getHTML('Enlarger_description') }
              </div>
              <img src="/images/enlarger2.jpg" alt="Enlarger example 2" className="img-fluid" />
            </div>
          </div>
        )}

        {this.state.watermark && !authenticated && (
          <div className="row highlight m-2">
            <div className="col-12">
              { intl.getHTML('MESSAGE_IMAGE_WITH_WATERMARKS') }
            </div>
          </div>
        )}

        <div className="row m-2">
          <div className="col-12">
            { intl.getHTML('Enlarger_instruction', { size_in_mb: TOTAL_FILE_SIZE / 1048576 }) } { intl.get('download_results_message') }
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12">
            <label className="btn mb-3 mr-3 btn-light">
              <input type="file" multiple accept="image/*" onChange={this.selectFiles} />
            </label>
          </div>
        </div>

        {previewImages && (
          <div className="row m-2">
            <div className="col-12">
              {previewImages.map((img, i) => {
                return <img className="preview" src={img} alt={"image-" + i}  key={i}/>;
              })}
            </div>
          </div>
        )}
        
        <div className="row m-2">
          <div className="col-12">
            { intl.get('Scale_Factor') } &nbsp;&nbsp;
            <select className="selectpicker" data-style="btn-success" onChange={this.onChangeOfScaleFactor}>
              {SCALE_OPTIONS.map(item => {
                  return (<option key={item.value} value={item.value}>{item.text}</option>);
              })}
            </select>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12 text-center">
            <button
              className="btn mb-3 mr-3 btn-info btn-lg"
              disabled={!(selectedFiles && !progress)}
              onClick={() => this.uploadImages(sid, locale)}
              >
              { intl.get('UPLOAD_TO_PROCESS') }
            </button>
          </div>
        </div>

        {selectedFiles && (
          <div className="progress m-2">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {message && (
          <div className="alert alert-warning my-1 text-wrap" role="alert">
            {message}
          </div> 
        )}

        {imageInfos.length > 0 && (
          <div className="card">
            <div className="card-header alert alert-success text-center">
              <h4>
                { intl.get('Results') }&nbsp;&nbsp;
                <PopupShare title={ intl.get('HEADLINE') } uri={window.location.pathname} tags={[intl.get('HEADLINE').replace(" ", "")]}/> 
              </h4> 
              { (authenticated && user.subscriptionPlanId) ? '' : intl.getHTML('Choose_paid_plan2') }
            </div>
            <ul className={ulClassName}>
              {imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  <img className="card-img-top" src={img.url} alt={img.name} />
                  <h6 className="card-title">{img.name}&nbsp;&nbsp;<a href={img.url}><b>{ intl.get('Download') }</b></a></h6>
                </li>
              ))}
            </ul>
          </div>
        )}
      </motion.div>
    );
  }
}
