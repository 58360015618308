import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import { motion } from 'framer-motion';
import { Capacitor } from '@capacitor/core';
import { SocialIcon } from 'react-social-icons';
import { MdAdsClick, MdOutlineBuild, MdOutlinePrivacyTip, MdOutlineImage, MdOutlineCamera } from "react-icons/md";
import parse from "html-react-parser";
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { SERVER_API_BASE_URL } from "../constants";
import { Context } from "../Context";
import { getSpecialMessageHTML } from "../utils";
/*
function FadeInWhenVisible({ className, children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1.0 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 }
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
}
*/
const Section = ({ name, gotoFunction, pictureOfTheDay, dynamicText, isLargeScreen, blogFeeds, locale, usageCount, textAtRight=true }) => {
  switch(name) {
    case 'Top':
      return (
        <motion.div className="headline">
            {/* <img src="/images/banner.jpg" alt="Peggy's Cove picture transformed" className="img-fluid" width="100%" /> */}
          {isLargeScreen ? (
            <div className="row m-3">
              <div className="col-12 text-center">
                <h2><font color='#556B2F'>{ intl.get('HEADLINE') }</font></h2>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 text-center">
                <h3><font color='#556B2F'>{ intl.get('HEADLINE') }</font></h3>
              </div>
            </div>
          )}
        </motion.div>
      );

    case 'Introduction-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.introductionVideo} title={ intl.get('YouTube_video_player') } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
              {/* <video title="Introduction to aiTransformer - Main Features" src={new URL(SERVER_API_BASE_URL).origin + '/assets/intro_video1.mp4'} controls autoPlay></video> */}
            </LazyLoadComponent>  
          </div>
          <div className="col-5 align-self-center text-center">
            <h5 align="left">{ intl.getHTML('SUBLINE', {'usage_count': usageCount}) }</h5>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h5 align="left">{ intl.getHTML('SUBLINE', {'usage_count': usageCount}) }</h5>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.introductionVideo} title={ intl.get('YouTube_video_player') } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
              {/* <video title="Introduction to aiTransformer - Main Features" src={new URL(SERVER_API_BASE_URL).origin + '/assets/intro_video1.mp4'} controls autoPlay></video> */}
            </LazyLoadComponent>  
          </div>
        </motion.div>
      );

    case 'Introduction-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h5 align="left">{ intl.getHTML('SUBLINE', {'usage_count': usageCount}) }</h5><p/>
            <div className="embed-responsive embed-responsive-16by9"> 
              <LazyLoadComponent>
                <iframe width="560" height="315" src={dynamicText.introductionVideo} title={ intl.get('YouTube_video_player') } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                {/* <video title="Introduction to aiTransformer - Main Features" src={new URL(SERVER_API_BASE_URL).origin + '/assets/intro_video1.mp4'} controls autoPlay></video> */}
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
      
    case 'Gallery-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src={pictureOfTheDay.url} alt={pictureOfTheDay.imageTitle} className="img-fluid" />
            <br/>  <br/><b>{pictureOfTheDay.imageTitle}</b>
            <br/>{pictureOfTheDay.imageCaption}
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Transformation_of_the_Day') }</h2><p/>
            <p align="left">{ intl.get('Gallery_info') }</p>
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/Gallery">
              { intl.get('Visit') + ' ' + intl.get('Demo_Transformation_Gallery') }
            </Link >
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/PromptBuilderSamplesGallery">
              { intl.get('Visit') + ' ' + intl.get('PromptBuilder_Samples_Gallery') }
            </Link >
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/ControlNetSamplesGallery">
              { intl.get('Visit') + ' ' + intl.get('ControlNet_Samples_Gallery') }
            </Link >
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Transformation_of_the_Day') }</h2><p/>
            <p align="left">{ intl.get('Gallery_info') }</p>
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/Gallery">
              { intl.get('Visit') + ' ' + intl.get('Demo_Transformation_Gallery') }
            </Link >
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/PromptBuilderSamplesGallery">
              { intl.get('Visit') + ' ' + intl.get('PromptBuilder_Samples_Gallery') }
            </Link >
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/ControlNetSamplesGallery">
              { intl.get('Visit') + ' ' + intl.get('ControlNet_Samples_Gallery') }
            </Link >
          </div>
          <div className="col-7 text-center">
            <img src={pictureOfTheDay.url} alt={pictureOfTheDay.imageTitle} className="img-fluid" />
            <br/>  <br/><b>{pictureOfTheDay.imageTitle}</b>
            <br/>{pictureOfTheDay.imageCaption}
          </div>
        </motion.div>
      ); 

    case 'Gallery-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Transformation_of_the_Day') }</h2><p/>
            <p align="left">{ intl.get('Gallery_info') }</p>
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/Gallery">
              { intl.get('Visit') + ' ' + intl.get('Demo_Transformation_Gallery') }
            </Link >
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/PromptBuilderSamplesGallery">
              { intl.get('Visit') + ' ' + intl.get('PromptBuilder_Samples_Gallery') }
            </Link >
            <Link className="btn mb-3 mr-3 btn-info btn-lg" to="/ControlNetSamplesGallery">
              { intl.get('Visit') + ' ' + intl.get('ControlNet_Samples_Gallery') }
            </Link >
            <p/>
            <div>
              <img src={pictureOfTheDay.url} alt={pictureOfTheDay.imageTitle} className="img-fluid" />
              <br/>  <br/><b>{pictureOfTheDay.imageTitle}</b>
              <br/>{pictureOfTheDay.imageCaption}
            </div>
          </div>
        </motion.div>
      );
  
    case 'SuperStylizer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.superStylizerVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />             
            </LazyLoadComponent> 
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Super_Stylizer') }</h2><p/>
            <p align="left">{ intl.get('SuperStylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('SuperStylizer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Super_Stylizer') }</h2><p/>
            <p align="left">{ intl.get('SuperStylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('SuperStylizer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.superStylizerVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />             
            </LazyLoadComponent> 
          </div>
        </motion.div>
      ); 

    case 'SuperStylizer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Super_Stylizer') }</h2><p/>
            <p align="left">{ intl.get('SuperStylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('SuperStylizer_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-16by9"> 
              <LazyLoadComponent>
                <iframe width="560" height="315" src={dynamicText.superStylizerVideo} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
      
    case 'VideoCartoonizer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.cartoonizerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />             
            </LazyLoadComponent>
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Cartoonizer') }</h2><p/>
            <p align="left">{ intl.get('VideoCartoonizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoCartoonizer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Cartoonizer') }</h2><p/>
            <p align="left">{ intl.get('VideoCartoonizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoCartoonizer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.cartoonizerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />             
            </LazyLoadComponent>
          </div>
        </motion.div>
      ); 

    case 'VideoCartoonizer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Video_Cartoonizer') }</h2><p/>
            <p align="left">{ intl.get('VideoCartoonizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoCartoonizer_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-16by9"> 
              <LazyLoadComponent>
                <iframe width="560" height="315" src={dynamicText.cartoonizerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
    
    case 'VideoEnhancer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.enhancerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
            </LazyLoadComponent>
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Enhancer') }</h2><p/>
            <p align="left">{ intl.get('VideoEnhancer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoEnhancer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Enhancer') }</h2><p/>
            <p align="left">{ intl.get('VideoEnhancer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoEnhancer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.enhancerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
            </LazyLoadComponent>
          </div>
        </motion.div>
      ); 

    case 'VideoEnhancer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Video_Enhancer') }</h2><p/>
            <p align="left">{ intl.get('VideoEnhancer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoEnhancer_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-16by9"> 
              <LazyLoadComponent>
                <iframe width="560" height="315" src={dynamicText.enhancerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
    
    case 'Cartoonizer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src="/images/cartoonizer1.jpg" alt="Cartoonizer example 1" className="img-fluid" />
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Cartoonizer') }</h2><p/>
            <p align="left">{ intl.get('Cartoonizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Cartoonizer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Cartoonizer') }</h2><p/>
            <p align="left">{ intl.get('Cartoonizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Cartoonizer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center">
            <img src="/images/cartoonizer1.jpg" alt="Cartoonizer example 1" className="img-fluid" />
          </div>
        </motion.div>
      ); 

    case 'Cartoonizer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Cartoonizer') }</h2><p/>
            <p align="left">{ intl.get('Cartoonizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Cartoonizer_button_text') }
            </button><p/>
            <div>
              <img src="/images/cartoonizer1.jpg" alt="Cartoonizer example 1" className="img-fluid" />
            </div>
          </div>
        </motion.div>
      );

    case 'Sketcher-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src="/images/sketcher1.jpg" alt="Sketcher example 1" className="img-fluid" />
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Sketcher') }</h2><p/>
            <p align="left">{ intl.get('Sketcher_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Sketcher_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Sketcher') }</h2><p/>
            <p align="left">{ intl.get('Sketcher_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Sketcher_button_text') }
            </button>
          </div>
          <div className="col-7 text-center">
            <img src="/images/sketcher1.jpg" alt="Sketcher example 1" className="img-fluid" />
          </div>
        </motion.div>
      ); 

    case 'Sketcher-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Sketcher') }</h2><p/>
            <p align="left">{ intl.get('Sketcher_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Sketcher_button_text') }
            </button><p/>
            <div>
              <img src="/images/sketcher1.jpg" alt="Sketcher example 1" className="img-fluid" />
            </div>
          </div>
        </motion.div>
      );
    
    case 'Enhancer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src="/images/enhancer1.jpg" alt="Enhancer example 1" className="img-fluid" />
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Enhancer') }</h2><p/>
            <p align="left">{ intl.get('Enhancer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Enhancer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Enhancer') }</h2><p/>
            <p align="left">{ intl.get('Enhancer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Enhancer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center">
            <img src="/images/enhancer1.jpg" alt="Enhancer example 1" className="img-fluid" />
          </div>
        </motion.div>
      ); 

    case 'Enhancer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Enhancer') }</h2><p/>
            <p align="left">{ intl.get('Enhancer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Enhancer_button_text') }
            </button><p/>
            <div>
              <img src="/images/enhancer1.jpg" alt="Enhancer example 1" className="img-fluid" />
            </div>
          </div>
        </motion.div>
      );

    case 'Stylizer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src="/images/stylizer1.jpg" alt="Stylizer example 1" className="img-fluid" />
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Stylizer') }</h2><p/>
            <p align="left">{ intl.get('Stylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Stylizer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Stylizer') }</h2><p/>
            <p align="left">{ intl.get('Stylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Stylizer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center">
            <img src="/images/stylizer1.jpg" alt="Stylizer example 1" className="img-fluid" />
          </div>
        </motion.div>
      ); 

    case 'Stylizer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Stylizer') }</h2><p/>
            <p align="left">{ intl.get('Stylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Stylizer_button_text') }
            </button><p/>
            <div>
              <img src="/images/stylizer1.jpg" alt="Stylizer example 1" className="img-fluid" />
            </div>
          </div>
        </motion.div>
      );
      
    case 'MultiStylizer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src="/images/multistylizer1.jpg" alt="MultiStylizer example 1" className="img-fluid" />
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('MultiStylizer') }</h2><p/>
            <p align="left">{ intl.get('MultiStylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('MultiStylizer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('MultiStylizer') }</h2><p/>
            <p align="left">{ intl.get('MultiStylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('MultiStylizer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center">
            <img src="/images/multistylizer1.jpg" alt="MultiStylizer example 1" className="img-fluid" />
          </div>
        </motion.div>
      ); 

    case 'MultiStylizer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('MultiStylizer') }</h2><p/>
            <p align="left">{ intl.get('MultiStylizer_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('MultiStylizer_button_text') }
            </button><p/>
            <div>
              <img src="/images/multistylizer1.jpg" alt="MultiStylizer example 1" className="img-fluid" />
            </div>
          </div>
        </motion.div>
      );

    case 'Enlarger-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src="/images/enlarger1.jpg" alt="Enlarger example 1" className="img-fluid" />
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Enlarger') }</h2><p/>
            <p align="left">{ intl.get('Enlarger_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Enlarger_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Enlarger') }</h2><p/>
            <p align="left">{ intl.get('Enlarger_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Enlarger_button_text') }
            </button>
          </div>
          <div className="col-7 text-center">
            <img src="/images/enlarger1.jpg" alt="Enlarger example 1" className="img-fluid" />
          </div>
        </motion.div>
      ); 

    case 'Enlarger-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Enlarger') }</h2><p/>
            <p align="left">{ intl.get('Enlarger_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Enlarger_button_text') }
            </button><p/>
            <div>
              <img src="/images/enlarger1.jpg" alt="Enlarger example 1" className="img-fluid" />
            </div>
          </div>
        </motion.div>
      );
      
    case 'VideoFilter-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.filterVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </LazyLoadComponent>
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Filter') }</h2><p/>
            <p align="left">{ intl.get('VideoFilter_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoFilter_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Filter') }</h2><p/>
            <p align="left">{ intl.get('VideoFilter_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoFilter_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.filterVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </LazyLoadComponent>
          </div>
        </motion.div>
      ); 

    case 'VideoFilter-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Video_Filter') }</h2><p/>
            <p align="left">{ intl.get('VideoFilter_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoFilter_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-16by9"> 
              <LazyLoadComponent>
                <iframe width="560" height="315" src={dynamicText.filterVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
      
    case 'Filter-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center">
            <img src="/images/filter1.jpg" alt="Filter example 1" className="img-fluid" />
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Filter') }</h2><p/>
            <p align="left">{ intl.get('Filter_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Filter_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Filter') }</h2><p/>
            <p align="left">{ intl.get('Filter_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Filter_button_text') }
            </button>
          </div>
          <div className="col-7 text-center">
            <img src="/images/filter1.jpg" alt="Filter example 1" className="img-fluid" />
          </div>
        </motion.div>
      ); 

    case 'Filter-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Filter') }</h2><p/>
            <p align="left">{ intl.get('Filter_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('Filter_button_text') }
            </button><p/>
            <div>
              <img src="/images/filter1.jpg" alt="Filter example 1" className="img-fluid" />
            </div>
          </div>
        </motion.div>
      );
      
    case 'BackgroundEditor-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-4by3">
            {/* <img src="/images/backgroundEditor1.jpg" alt="Background Editor example 1" className="img-fluid" /> */}
            <LazyLoadComponent>
              <video className="embed-responsive-item" title="Changing background made easy" src={new URL(SERVER_API_BASE_URL).origin + '/assets/BackgroundEditor1.mp4'} type="video/mp4" controls autoPlay></video> 
            </LazyLoadComponent>
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Background_Editor') }</h2><p/>
            <p align="left">{ intl.get('BackgroundEditor_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('BackgroundEditor_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Background_Editor') }</h2><p/>
            <p align="left">{ intl.get('BackgroundEditor_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('BackgroundEditor_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-4by3">
            {/* <img src="/images/backgroundEditor1.jpg" alt="Background Editor example 1" className="img-fluid" /> */}
            <LazyLoadComponent>
              <video className="embed-responsive-item" title="Changing background made easy" src={new URL(SERVER_API_BASE_URL).origin + '/assets/BackgroundEditor1.mp4'} type="video/mp4" controls autoPlay></video> 
            </LazyLoadComponent>
          </div>
        </motion.div>
      ); 
  
    case 'BackgroundEditor-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Background_Editor') }</h2><p/>
            <p align="left">{ intl.get('BackgroundEditor_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('BackgroundEditor_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-4by3">
              {/* <img src="/images/backgroundEditor1.jpg" alt="Background Editor example 1" className="img-fluid" /> */}
              <LazyLoadComponent>
                <video className="embed-responsive-item" title="Changing background made easy" src={new URL(SERVER_API_BASE_URL).origin + '/assets/BackgroundEditor1.mp4'} type="video/mp4" controls autoPlay></video> 
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
  
    case 'VideoBackgroundEditor-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-4by3">
            <LazyLoadComponent>
              <video className="embed-responsive-item" title="Ducks visiting the magical land" type="video/mp4" controls autoPlay 
                poster={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor1 - thumbnail.jpg'}
                src={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor1.mp4'} />
            </LazyLoadComponent>
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Background_Editor') }</h2><p/>
            <p align="left">{ intl.get('VideoBackgroundEditor_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoBackgroundEditor_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Video_Background_Editor') }</h2><p/>
            <p align="left">{ intl.get('VideoBackgroundEditor_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoBackgroundEditor_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-4by3">
            <LazyLoadComponent>
              <video className="embed-responsive-item" title="Ducks visiting the magical land" type="video/mp4" controls autoPlay 
                poster={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor1 - thumbnail.jpg'}
                src={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor1.mp4'} />
            </LazyLoadComponent>
          </div>
        </motion.div>
      ); 

    case 'VideoBackgroundEditor-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Video_Background_Editor') }</h2><p/>
            <p align="left">{ intl.get('VideoBackgroundEditor_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('VideoBackgroundEditor_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-4by3">
              <LazyLoadComponent>
                <video className="embed-responsive-item" title="Ducks visiting the magical land" type="video/mp4" controls autoPlay 
                  poster={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor1 - thumbnail.jpg'}
                  src={new URL(SERVER_API_BASE_URL).origin + '/assets/VideoBackgroundEditor1.mp4'} />
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
      
    case 'SpeechSynthesizer-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-4by3">
          {/*<div className="col-7 text-center embed-responsive embed-responsive-16by9">*/}
            <LazyLoadComponent>
              <video className="embed-responsive-item" title={ intl.get('SpeechSynthesizer_demo_video') + '_1'} src={new URL(SERVER_API_BASE_URL).origin + '/assets/SpeechSynthesizer1_' + locale.substring(0, 2) + '.mp4'} type="video/mp4" controls autoPlay></video>
              {/*<iframe width="560" height="315" src={dynamicText.greetingsVideo} title={ intl.get('YouTube_video_player') } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />*/}
            </LazyLoadComponent>
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Speech_Synthesizer') }</h2><p/>
            <p align="left">{ intl.get('SpeechSynthesizer_info') }&nbsp;{ intl.get('Welcome_text') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('SpeechSynthesizer_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Speech_Synthesizer') }</h2><p/>
            <p align="left">{ intl.get('SpeechSynthesizer_info') }&nbsp;{ intl.get('Welcome_text') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('SpeechSynthesizer_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-4by3">
          {/*<div className="col-7 text-center embed-responsive embed-responsive-16by9">*/} 
            <LazyLoadComponent>
              <video className="embed-responsive-item" title={ intl.get('SpeechSynthesizer_demo_video') + '_1'} src={new URL(SERVER_API_BASE_URL).origin + '/assets/SpeechSynthesizer1_' + locale.substring(0, 2) + '.mp4'} type="video/mp4" controls autoPlay></video>
              {/*<iframe width="560" height="315" src={dynamicText.greetingsVideo} title={ intl.get('YouTube_video_player') } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />*/}
            </LazyLoadComponent>
          </div>
        </motion.div>
      ); 
  
    case 'SpeechSynthesizer-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Speech_Synthesizer') }</h2><p/>
            <p align="left">{ intl.get('SpeechSynthesizer_info') }&nbsp;{ intl.get('Welcome_text') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('SpeechSynthesizer_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-4by3">
            {/*<div className="embed-responsive embed-responsive-16by9">*/}
              <LazyLoadComponent>
                <video className="embed-responsive-item" title={ intl.get('SpeechSynthesizer_demo_video') + '_1'} src={new URL(SERVER_API_BASE_URL).origin + '/assets/SpeechSynthesizer1_' + locale.substring(0, 2) + '.mp4'} type="video/mp4" controls autoPlay></video>
                {/*<iframe width="560" height="315" src={dynamicText.greetingsVideo} title={ intl.get('YouTube_video_player') } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />*/}
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
  
    case 'StorybookMaker-1':
      return textAtRight ? (
        <motion.div className="row m-5">
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.storybookMakerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
            </LazyLoadComponent>
          </div>
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Storybook_Maker') }</h2><p/>
            <p align="left">{ intl.get('StorybookMaker_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('StorybookMaker_button_text') }
            </button>
          </div>
        </motion.div>
      ) : (
        <motion.div className="row m-5">
          <div className="col-5 align-self-center text-center">
            <h2>{ intl.get('Storybook_Maker') }</h2><p/>
            <p align="left">{ intl.get('StorybookMaker_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('StorybookMaker_button_text') }
            </button>
          </div>
          <div className="col-7 text-center embed-responsive embed-responsive-16by9"> 
            <LazyLoadComponent>
              <iframe width="560" height="315" src={dynamicText.storybookMakerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
            </LazyLoadComponent>
          </div>
        </motion.div>
      ); 

    case 'StorybookMaker-2':
      return (
        <motion.div className="row">
          <div className="col-12 text-center">
            <h2>{ intl.get('Storybook_Maker') }</h2><p/>
            <p align="left">{ intl.get('StorybookMaker_info') }</p>
            <button className="btn mb-3 mr-3 btn-info btn-lg" onClick={gotoFunction}>
              { intl.get('StorybookMaker_button_text') }
            </button><p/>
            <div className="embed-responsive embed-responsive-16by9"> 
              <LazyLoadComponent>
                <iframe width="560" height="315" src={dynamicText.storybookMakerVideo1} title={ intl.get('YouTube_video_player') } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />              
              </LazyLoadComponent>
            </div>
          </div>
        </motion.div>
      );
      
      case 'Highlights':
      return (
        <motion.div className="container-fluid text-center">
          <h4>{ intl.get('Highlights') }</h4>
          <br/>
          <div className="row">
            <div className="col-sm-6">
              <h4><MdAdsClick size={32}/>&nbsp;&nbsp;{ intl.get('Highlight1_title') }</h4>
              <p align="left">{ intl.get('Highlight1') }</p>
            </div>
            <div className="col-sm-6">
              <h4><MdOutlineBuild size={32} />&nbsp;&nbsp;{ intl.get('Highlight2_title') }</h4>
              <p align="left">{ intl.get('Highlight2') }</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h4><MdOutlinePrivacyTip size={32} />&nbsp;&nbsp;{ intl.get('Highlight3_title') }</h4>
              <p align="left">{ intl.get('Highlight3') }</p>
            </div>
            <div className="col-sm-6">
              <h4><MdOutlineImage size={32} />&nbsp;&nbsp;{ intl.get('Highlight4_title') }</h4>
              <p align="left">{ intl.get('Highlight4') }</p>
            </div>
          </div>
        </motion.div>
      );
  
    case 'BlogFeeds':
      return (
        <motion.div className="container-fluid">
        {blogFeeds.length > 0 && (
        <div>
          <h3 align="center"><font color="Indigo"><MdOutlineCamera size={32} />&nbsp;&nbsp;<em>{ intl.get('BlogFeeds') }</em>&nbsp;&nbsp;<MdOutlineCamera size={32} /></font></h3>
          <br/>
          <div className="row">
            <div className="col-sm-6">
              <a href={blogFeeds[0].href} target="_blank" rel="noreferrer">{blogFeeds[0].title}</a>
              <p>{blogFeeds[0].updated}</p>
            </div>
            <div className="col-sm-6">
              <a href={blogFeeds[1].href} target="_blank" rel="noreferrer">{blogFeeds[1].title}</a>
              <p>{blogFeeds[1].updated}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
             <a href={blogFeeds[2].href} target="_blank" rel="noreferrer">{blogFeeds[2].title}</a>
              <p>{blogFeeds[2].updated}</p>
            </div>
            <div className="col-sm-6">
              <a href={blogFeeds[3].href} target="_blank" rel="noreferrer">{blogFeeds[3].title}</a>
              <p>{blogFeeds[3].updated}</p>
            </div>
          </div>
        </div>
        )}
        </motion.div>
      );
    
    default:
      return (
        <div className="row m-1" />
      );

    }
};

const HomePage = (props) => {
  const navigate = useNavigate();
  const gotoCartoonizer = () => navigate('/Cartoonizer');
  const gotoVideoCartoonizer = () => navigate('/VideoCartoonizer');
  const gotoEnhancer = () => navigate('/Enhancer');
  const gotoVideoEnhancer = () => navigate('/VideoEnhancer');
  const gotoSketcher = () => navigate('/Sketcher');
  const gotoStylizer = () => navigate('/Stylizer');
  const gotoMultiStylizer = () => navigate('/MultiStylizer');
  //const gotoSuperStylizer = () => navigate('/SuperStylizer');
  const gotoEnlarger = () => navigate('/Enlarger');
  const gotoFilter = () => navigate('/Filter');
  const gotoVideoFilter = () => navigate('/VideoFilter');
  const gotoBackgroundEditor = () => navigate('/BackgroundEditor');
  const gotoVideoBackgroundEditor = () => navigate('/VideoBackgroundEditor');
  const gotoSpeechSynthesizer = () => navigate('/SpeechSynthesizer');
  const gotoStorybookMaker = () => navigate('/StorybookMaker');
  const gotoGallery = () => navigate('/Gallery');

  const [pictureOfTheDay, setPictureOfTheDay] = useState(undefined);
  const [blogFeeds, setBlogFeeds] = useState(undefined);
  useEffect(() => {  //this should only run once to get pictureOfTheDay and blogFeeds
    fetch(`${SERVER_API_BASE_URL}/gallery/demo?limit=top1`)
      .then(response => response.json())
      .then(imageInfos => setPictureOfTheDay(imageInfos[0]));

    fetch(`${SERVER_API_BASE_URL}/blog/posts?limit=latest4`)
      .then(response => response.json())
      .then(data => setBlogFeeds(data));
  }, []);

  const { authenticated, user, dynamicText, locale, usageCount } = useContext(Context);
  const specialMessage = getSpecialMessageHTML(authenticated, user, dynamicText);

  return (
    <div className="content">
      <Helmet>
        <title>{ intl.get('APP_TTITLE') }</title>
        <meta name="description" content={ intl.get('APP_DESCRIPTION') } />
      </Helmet>
      <Section name='Top' isLargeScreen={props.isLargeScreen} />

      {specialMessage && (
        <div className="alert alert-danger mt-2">
          {parse(specialMessage)}
        </div>
      )}
        
      {props.isLargeScreen ? (
        <Section name='Introduction-1' dynamicText={dynamicText} usageCount={usageCount} />
      ) : (
        <Section name='Introduction-2' dynamicText={dynamicText} usageCount={usageCount} />
      )}

      {Capacitor.isNativePlatform() ? (
        <div className="container-fluid text-center">
          { intl.get('Introduction_video_on_YouTube') }<SocialIcon url="https://www.youtube.com/playlist?list=PLBL9ZLfl7fz8sOnh1YcE73xRRQOeWFn_X" target="_blank" rel="noreferrer" />
        </div>
      ) : (
        <div className="row container-fluid text-center">
          <div className="col-sm-6">
            { intl.get('Introduction_video_on_YouTube') }<SocialIcon style={{ height:100, width:120 }} url="https://www.youtube.com/playlist?list=PLBL9ZLfl7fz8sOnh1YcE73xRRQOeWFn_X" target="_blank" rel="noreferrer" />
          </div>
          <div className="col-sm-6">
            { intl.get('App_on_Google_Play') }<a href='https://play.google.com/store/apps/details?id=net.aifinancials.imagetransformer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noreferrer"><img alt='Get it on Google Play' src='google-play-badge.png' /></a>
          </div>
        </div>
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='SpeechSynthesizer-1' gotoFunction={gotoSpeechSynthesizer} locale={locale} dynamicText={dynamicText} textAtRight={false} />
      ) : (
        <Section name='SpeechSynthesizer-2' gotoFunction={gotoSpeechSynthesizer} locale={locale} dynamicText={dynamicText} />
      )}

      {pictureOfTheDay && props.isLargeScreen && (
        <div className="row m-5" />
      )}
      {pictureOfTheDay && !props.isLargeScreen && (
        <div className="row m-3" />
      )}
      {pictureOfTheDay && props.isLargeScreen && (
        <Section name='Gallery-1' gotoFunction={gotoGallery} pictureOfTheDay={pictureOfTheDay} />
      )}
      {pictureOfTheDay && !props.isLargeScreen && (
        <Section name='Gallery-2' gotoFunction={gotoGallery} pictureOfTheDay={pictureOfTheDay} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='StorybookMaker-1' gotoFunction={gotoStorybookMaker} dynamicText={dynamicText} textAtRight={false} />
      ) : (
        <Section name='StorybookMaker-2' gotoFunction={gotoStorybookMaker} dynamicText={dynamicText} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='BackgroundEditor-1' gotoFunction={gotoBackgroundEditor} />
      ) : (
        <Section name='BackgroundEditor-2' gotoFunction={gotoBackgroundEditor} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='VideoBackgroundEditor-1' gotoFunction={gotoVideoBackgroundEditor} dynamicText={dynamicText} textAtRight={false} />
      ) : (
        <Section name='VideoBackgroundEditor-2' gotoFunction={gotoVideoBackgroundEditor} dynamicText={dynamicText} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='Cartoonizer-1' gotoFunction={gotoCartoonizer} />
      ) : (
        <Section name='Cartoonizer-2' gotoFunction={gotoCartoonizer} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='VideoCartoonizer-1' gotoFunction={gotoVideoCartoonizer} dynamicText={dynamicText} textAtRight={false} />
      ) : (
        <Section name='VideoCartoonizer-2' gotoFunction={gotoVideoCartoonizer} dynamicText={dynamicText} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='Enhancer-1' gotoFunction={gotoEnhancer} />
      ) : (
        <Section name='Enhancer-2' gotoFunction={gotoEnhancer} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='VideoEnhancer-1' gotoFunction={gotoVideoEnhancer} dynamicText={dynamicText} textAtRight={false} />
      ) : (
        <Section name='VideoEnhancer-2' gotoFunction={gotoVideoEnhancer} dynamicText={dynamicText} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='Sketcher-1' gotoFunction={gotoSketcher} />
      ) : (
        <Section name='Sketcher-2' gotoFunction={gotoSketcher} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='Stylizer-1' gotoFunction={gotoStylizer} textAtRight={false} />
      ) : (
        <Section name='Stylizer-2' gotoFunction={gotoStylizer} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='MultiStylizer-1' gotoFunction={gotoMultiStylizer} />
      ) : (
        <Section name='MultiStylizer-2' gotoFunction={gotoMultiStylizer} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='Enlarger-1' gotoFunction={gotoEnlarger} textAtRight={false} />
      ) : (
        <Section name='Enlarger-2' gotoFunction={gotoEnlarger} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='Filter-1' gotoFunction={gotoFilter} />
      ) : (
        <Section name='Filter-2' gotoFunction={gotoFilter} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      {props.isLargeScreen ? (
        <Section name='VideoFilter-1' gotoFunction={gotoVideoFilter} dynamicText={dynamicText} textAtRight={false} />
      ) : (
        <Section name='VideoFilter-2' gotoFunction={gotoVideoFilter} dynamicText={dynamicText} />
      )}

      {props.isLargeScreen ? (
        <div className="row m-5" />
      ) : (
        <div className="row m-3" />
      )}
      <Section name='Highlights' />

      <div className="row m-3" />
      {blogFeeds && (
        <Section name='BlogFeeds' blogFeeds={blogFeeds} />
      )}
      <div className="row m-3" />

    </div>
  );
}

export default HomePage;
